import CheckIcon from '@assets/icons/check.svg';
import { Colors } from '@/lib/utils/enums/colors';
import clsx from 'clsx';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface MultiSelectProps<FV extends FieldValues> {
  value: string;
  selected: boolean;
  onSelect: (value: any) => void;
  highlightColor?: string;
  as?: 'radio' | 'button' | 'checkbox';
  name?: string;

  register?: UseFormRegister<FV>;
  label?: Path<FV>;
  required?: boolean;
}

const MultiSelectButton = <FV extends FieldValues>({
  value,
  selected,
  onSelect,
  highlightColor = 'bg-ruby',
  as = 'button',
  name,
  register,
  label,
  required = false,
}: MultiSelectProps<FV>) => {
  const ButtonComponent = () => (
    <button
      className={clsx(
        'relative flex w-full items-center justify-center rounded py-3 font-moderat capitalize ring ring-black/10',
        selected && highlightColor === Colors.RoyalBlue ?
          'bg-royal-blue'
        : undefined,
        selected && highlightColor === Colors.Ruby ? 'bg-ruby' : undefined,
        selected && highlightColor === Colors.Black ? 'bg-black' : undefined,
        selected && 'text-white'
      )}
      onClick={() => onSelect(value)}
    >
      {value}
      {selected && <CheckIcon className={'absolute right-4 h-4 w-4'} />}
    </button>
  );

  const RadioComponent = () => (
    <label
      htmlFor={value}
      className={clsx(
        'relative flex w-full items-center justify-center rounded py-3 capitalize',
        selected && highlightColor === Colors.RoyalBlue ?
          'bg-royal-blue'
        : 'bg-neutral-200',
        selected && highlightColor === Colors.Ruby ?
          'bg-ruby'
        : 'bg-neutral-200',
        selected && 'text-white'
      )}
    >
      <input
        type={'radio'}
        name={name}
        value={value}
        id={value}
        className={'hidden'}
        onClick={() => onSelect(value)}
        {...(register ?
          register(label!, {
            required: {
              value: required,
              message: 'This field is required',
            },
          })
        : null)}
      />{' '}
      {value} {selected && <CheckIcon className={'absolute right-4 h-4 w-4'} />}
    </label>
  );

  const CheckboxComponent = () => (
    <label
      htmlFor={value}
      className={clsx(
        'relative flex w-full items-center justify-center rounded py-3 capitalize',
        selected && highlightColor === Colors.RoyalBlue ?
          'bg-royal-blue'
        : 'bg-neutral-200',
        selected && highlightColor === Colors.Ruby ?
          'bg-ruby'
        : 'bg-neutral-200',
        selected && 'text-white'
      )}
    >
      <input
        type={'checkbox'}
        name={name}
        value={value}
        id={value}
        className={'hidden'}
        onClick={() => onSelect(value)}
        {...(register ?
          register(label!, {
            required: {
              value: required,
              message: 'This field is required',
            },
          })
        : null)}
      />{' '}
      {value} {selected && <CheckIcon className={'absolute right-4 h-4 w-4'} />}
    </label>
  );

  switch (as) {
    case 'button':
      return <ButtonComponent />;
    case 'radio':
      return <RadioComponent />;
    case 'checkbox':
      return <RadioComponent />;
  }
};

export default MultiSelectButton;
