import { ServerResponse } from '@/lib/types';
import { env } from '@/lib/env';
import { AxiosHeaders } from 'axios';
import { CreateJobDto, UpdateJobDto } from '@/modules/job/job.dto';
import { Job } from '@/modules/job/job.model';
import jobreelApi from '@/lib/utils/jobreel-api';
import { getBearerToken } from '@/lib/utils/functions/authentication';

export const Job_List = async (query: { business: string }): Promise<Job[]> => {
  const { data } = await jobreelApi.get<ServerResponse<Job[]>>(
    env.baseApiUrl + '/v1/jobs',
    {
      params: query,
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data.data;
};

export const Job_Get = async (hash: string): Promise<Job> => {
  const headers = new AxiosHeaders();
  const token = getBearerToken();

  if (token) {
    headers.set('Authorization', token);
  }

  const { data } = await jobreelApi.get<ServerResponse<Job>>(
    env.baseApiUrl + '/v1/jobs/' + hash,
    {
      headers,
    }
  );

  return data.data;
};

export const Job_FindBySlug = async (
  businessSlug: string,
  jobSlug: string
): Promise<Job> => {
  const { data } = await jobreelApi.get<ServerResponse<Job>>(
    env.baseApiUrl + '/v1/jobs/findBySlug',
    {
      params: {
        businessSlug: businessSlug,
        jobSlug: jobSlug,
      },
    }
  );

  return data.data;
};

export const Job_Create = async (payload: CreateJobDto) => {
  const { data } = await jobreelApi.post<ServerResponse<Job>>(
    `/v1/jobs`,
    payload,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};

export const Job_Update = async (jobHash: string, payload: UpdateJobDto) => {
  const { data } = await jobreelApi.patch<ServerResponse<Job>>(
    `/v1/jobs/${jobHash}`,
    payload,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};

export async function Job_GetLink(jobHash: string) {
  const { data } = await jobreelApi.get<
    ServerResponse<{ qrCode: string; url: string }>
  >(`/v1/jobs/${jobHash}/link`, {
    headers: {
      Authorization: getBearerToken(),
    },
  });
  return data.data;
}

export async function Job_Delete(jobHash: string) {
  const { data } = await jobreelApi.delete<ServerResponse<Job>>(
    `/v1/jobs/${jobHash}`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
}

// export const Job_Create = async (
//     payload: IOfferForm & { types: PositionType[] },
// ): Promise<Job> => {
//     const body = {
//         ...payload,
//         business: getCurrentBusiness(),
//         actively_hiring: true,
//     }
//     const { data } = await jobreelApi.post<ServerResponse<Job>>(
//         env.baseApiUrl + "/v1/jobs",
//         body,
//         {
//             headers: {
//                 Authorization: getBearerToken(),
//             },
//         },
//     )

//     return data.data
// }

// export const Job_Update = async (
//     hash: string,
//     payload: IOfferForm & { types: PositionType[] },
// ): Promise<Job> => {
//     const body = { ...payload, business: getCurrentBusiness() }
//     const { data } = await jobreelApi.patch<ServerResponse<Job>>(
//         env.baseApiUrl + "/v1/jobs/" + hash,
//         body,
//         {
//             headers: {
//                 Authorization: getBearerToken(),
//             },
//         },
//     )
//     return data.data
// }

export const Offer_Delete = async (hash: string) => {};
