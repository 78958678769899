'use client';

import { Dialog, Transition } from '@headlessui/react';
import MultiSelectButton from '@/components/buttons/MultiSelectButton';
import { Colors } from '@/lib/utils/enums/colors';
import { useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import { Drawer } from 'vaul';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { JobAddress } from '@/modules/job/job.model';

type AddressOverlayV2Props = {
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  addresses: JobAddress[];
  onSubmit: (addresses?: string[]) => Promise<void>;
};

const content = {
  title: 'What location(s) would you like to apply to?',
  description: 'Select all you are interested in.',
};

export function AddressOverlayV2({
  open,
  setOpen,
  addresses,
  onSubmit,
}: AddressOverlayV2Props) {
  const { isMobile } = useIsMobile();
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);

  const onSelectAddress = (address: string) => {
    setSelectedAddresses((prev) => {
      const index = prev.findIndex((addr) => addr === address);

      if (index === -1) {
        return [...prev, address];
      }

      return prev.toSpliced(index, 1);
    });
  };

  const onApply = async () => {
    if (!selectedAddresses) {
      toast('Please select at least 1 address.');
      return;
    }

    return await onSubmit(selectedAddresses);
  };

  const onClose = () => {
    setSelectedAddresses([]);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setOpen(open);
    } else {
      onClose();
    }
  };

  return (
    <>
      {!isMobile && (
        <Transition
          show={open}
          enter='ease-out duration-200'
          enterFrom='opacity-0 scale-90'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-300'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-90'
        >
          <Dialog
            onClose={onClose}
            className='relative isolate z-100 transition'
          >
            <Transition.Child
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay
                className='fixed inset-0 bg-black/60 backdrop-blur-[1px] transition'
                aria-hidden='true'
              />
            </Transition.Child>

            <div className='fixed inset-0 flex w-screen items-center justify-center p-4 transition'>
              <Dialog.Panel className='flex flex-col max-w-lg max-h-[95svh] border bg-white py-4 rounded-lg transition'>
                <div className='flex flex-col space-y-2 px-8 py-4'>
                  <div className='flex flex-col'>
                    <CloseButton onClose={onClose} />
                    <Dialog.Title className='font-bold text-2xl'>
                      {content.title}
                    </Dialog.Title>
                  </div>
                  <Dialog.Description className='text-zinc-500 text-base'>
                    {content.description}
                  </Dialog.Description>
                </div>

                <div className='overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full py-4 px-8'>
                  <AddressMultiSelect
                    // @ts-ignore
                    addresses={addresses}
                    selectedAddresses={selectedAddresses}
                    onSelect={onSelectAddress}
                  />
                </div>

                <div className='flex items-center justify-center px-8 py-4'>
                  <ApplyButton
                    addresses={selectedAddresses}
                    onApply={onApply}
                  />
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition>
      )}
      {isMobile && (
        <Drawer.Root
          open={open}
          onOpenChange={onOpenChange}
          disablePreventScroll
        >
          <Drawer.Portal>
            <Drawer.Overlay className='fixed inset-0 bg-black/60 backdrop-blur-[1px]' />

            <Drawer.Content className='fixed bottom-0 left-0 right-0 flex flex-col rounded-t-[10px] h-fit max-h-[95svh] bg-white py-4 sm:py-8 isolate z-100'>
              <div className='flex items-center justify-between px-4 mb-2'>
                <div />
                <Drawer.Handle className='bg-zinc-400' />
                <CloseButton onClose={onClose} />
              </div>

              <div className='flex flex-col sm:text-center pb-4 px-4'>
                <Drawer.Title className='font-bold text-2xl mb-2'>
                  {content.title}
                </Drawer.Title>

                <Drawer.Description className='text-zinc-500 text-base'>
                  {content.description}
                </Drawer.Description>
              </div>

              <div className='overflow-y-scroll scrollbar scrollbar-w-1.5 scrollbar-thumb-zinc-400 scrollbar-thumb-rounded-full p-4'>
                <AddressMultiSelect
                  addresses={addresses}
                  selectedAddresses={selectedAddresses}
                  onSelect={onSelectAddress}
                />
              </div>

              <div className='flex justify-center pt-4 px-4'>
                <ApplyButton addresses={selectedAddresses} onApply={onApply} />
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      )}
    </>
  );
}

type ApplyButtonProps = {
  onApply: () => void;
  addresses: string[];
};

function ApplyButton({ onApply, addresses }: ApplyButtonProps) {
  return (
    <button
      className='inline-flex items-center justify-center rounded-full px-6 h-12 font-normal capitalize bg-ruby hover:bg-ruby/90 text-white disabled:cursor-not-allowed disabled:bg-neutral-300 transition-colors'
      onClick={onApply}
      disabled={!addresses.length}
    >
      Apply with Jobreel
    </button>
  );
}

type AddressMultiSelectProps = {
  addresses: JobAddress[];
  selectedAddresses: string[];
  onSelect: (address: string) => void;
};

function AddressMultiSelect({
  addresses,
  selectedAddresses,
  onSelect,
}: AddressMultiSelectProps) {
  return (
    <ul className='flex flex-col space-y-4 w-full'>
      {addresses.map((addr, i) => (
        <li key={i}>
          <MultiSelectButton
            selected={selectedAddresses.includes(addr.hash)}
            value={`${!!addr.fullThoroughfare ? `${addr.fullThoroughfare}, ` : ''}${addr.locality + ', ' || ''}${addr.postalCode || ''}`}
            onSelect={() => onSelect(addr.hash)}
            highlightColor={Colors.Black}
          />
        </li>
      ))}
    </ul>
  );
}

type CloseButtonProps = {
  onClose: () => void;
};

function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <button
      onClick={onClose}
      className='self-end mb-2 bg-transparent rouded-full'
    >
      <XCircleIcon className='w-6 h-6' />
    </button>
  );
}

export default AddressOverlayV2;
