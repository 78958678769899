import { Application } from '@/modules/application/application.model';
import jobreelApi from '@/lib/utils/jobreel-api';
import { getBearerToken } from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';

async function listForUser(query: { limit: number; skip: number }) {
  const { data } = await jobreelApi.get<{
    data: Application[];
    hasMore: boolean;
    limit: number;
    skip: number;
    total: number;
  }>(`/v2/applications/listForUser`, {
    headers: {
      Authorization: getBearerToken(),
    },
    params: query,
  });

  return data;
}

async function listForJobAndAddress(query: {
  limit: number;
  skip: number;
  addressHash: string;
  businessHash: string;
  jobHash: string;
}) {
  const { data } = await jobreelApi.get<{
    hasMore: boolean;
    limit: number;
    skip: number;
    total: number;
    data: Application[];
  }>(`/v2/applications/listForJobAndAddress`, {
    headers: {
      Authorization: getBearerToken(),
    },
    params: query,
  });

  return data;
}

async function getAppliedAddressesForJob(jobHash: string) {
  const { data } = await jobreelApi.get<{
    appliedAddressHashes: string[];
  }>(`/v2/applications/appliedAddressesForJob/${jobHash}`, {
    headers: {
      Authorization: getBearerToken(),
    },
  });

  return data.appliedAddressHashes;
}

async function createApplication(payload: {
  jobHash: string;
  addressHash: string;
  profileHash: string;
  reelHash: string;
  source?: string;
}) {
  const { data } = await jobreelApi.post<ServerResponse<Application>>(
    `/v2/applications`,
    {
      ...payload,
      source: payload.source ?? 'web-client',
    },
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data.data;
}

async function updateApplication(applicationHash: string, payload: any) {
  const { data } = await jobreelApi.patch<ServerResponse<Application>>(
    `/v1/applications/${applicationHash}`,
    payload,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data.data;
}

export const applicationService = {
  create: createApplication,
  update: updateApplication,
  listForUser,
  listForJobAndAddress,
  getAppliedAddressesForJob,
};
