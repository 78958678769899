import { Address } from '@/modules/address/address.model';
import { Job, JobStatus } from '@/modules/job/job.model';
import { getBearerToken } from '@/lib/utils/functions/authentication';
import jobreelApi from '@/lib/utils/jobreel-api';

export type JobWithAddressAndStats = {
  address: Address;
  job: Job;
  numberOfApplicants: number;
  numberOfConversations: number;
  numberOfNewApplicants: number;
  numberOfUnreadConversations: number;
};

async function listJobPerAddress(params?: {
  businessHash: string;
  addressHash?: string;
  limit?: number;
  skip?: number;
  filterStatus?: JobStatus;
}) {
  const { data } = await jobreelApi.get<{
    hasMore: boolean;
    data: JobWithAddressAndStats[];
    skip: number;
    limit: number;
    total: number;
  }>(`/v2/jobs/listPerAddress`, {
    headers: {
      Authorization: getBearerToken(),
    },
    params: params,
  });
  return data;
}



export const jobService = {
  listJobPerAddress,
};
