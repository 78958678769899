'use client';
import { useJob } from '@/modules/job/job.queries';
import { useProfile } from '@/modules/profile/profile.queries';
import { useReel } from '@/modules/reel/reel.queries';
import { Application } from '@/modules/application/application.model';
import { MuxStatus } from '@/modules/reel/reel.model';
import { applicationService } from '@/chore/services/v2/Application.service';
import AddressOverlayV2 from '@/components/AddressOverlay/AddressOverlayV2';
import { Loader } from '@/components/common/loaders/loaders';
import { cn } from '@/lib/utils/cn';
import { isAxiosError } from 'axios';
import { useRouter, useSearchParams } from 'next/navigation';
import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { jobAddresses } from '@/lib/utils/functions/authentication';

type ApplyButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> & {
  isLoading?: boolean;
  isLoadingChidlren?: ReactNode;
  onSuccessUrl?: string;
  onApplySuccess?: (applications: Application[]) => void | Promise<void>;
  onStateChange?: ({
    isApplying,
    reelStatus,
  }: {
    isApplying: boolean;
    reelStatus?: MuxStatus;
  }) => void | Promise<void>;
};

export const ApplyButton = forwardRef<HTMLButtonElement, ApplyButtonProps>(
  function ApplyButton(
    {
      children,
      isLoadingChidlren,
      className,
      disabled,

      isLoading,
      onSuccessUrl,
      onApplySuccess,
      onStateChange,
      ...props
    },
    ref
  ) {
    const router = useRouter();
    const searchParams = useSearchParams();
    const [isApplying, setIsApplying] = useState(false);
    const [showAddresses, setShowAddresses] = useState(false);
    const [isStillLoading, setIsStillLoading] = useState(false);
    const { data: reel } = useReel(searchParams.get('reel') ?? '');
    const { data: job } = useJob(searchParams.get('job') ?? '');
    const { data: profile } = useProfile();

    const isReelReady = reel?.muxStatus === 'ready';
    const isPreparingReel =
      reel?.muxStatus === 'preparing' || reel?.muxStatus === 'created';
    const isReelErrored = reel?.muxStatus === 'errored';

    useEffect(() => {
      if (isReelErrored) {
        toast.error('There was an error processing your video.');
      }
    }, [isReelErrored]);

    useEffect(() => {
      if (onStateChange) {
        onStateChange({ isApplying, reelStatus: reel?.muxStatus });
      }
    }, [onStateChange, isApplying, reel?.muxStatus]);

    const isDisabled =
      disabled ||
      isApplying ||
      isLoading ||
      isReelErrored ||
      (isPreparingReel && isStillLoading);

    const onApply = useCallback(async () => {
      if (isDisabled) return;

      if (isPreparingReel) {
        setIsStillLoading(true);
        return;
      }

      try {
        setIsApplying(true);

        let addresses = jobAddresses();

        if (!job) {
          return toast.error("Job isn't set");
        }

        if (!addresses?.length) {
          if (job?.addresses?.length > 1) {
            setShowAddresses(true);
            return;
          }

          addresses = [job.addresses[0].hash];
        }

        if (!reel) {
          return toast.error("reel isn't set");
        }

        if (!profile) {
          return toast.error('profile not found');
        }

        let successUrl = `/app/inbox`;

        if (!profile.isPublic) {
          onSuccessUrl = `/onboarding/candidate/go-public?reel=${reel.hash}&job=${job.hash}`;
        }

        if (onSuccessUrl) {
          successUrl = onSuccessUrl;
        }

        const applicationsRes = await Promise.allSettled(
          addresses.map((addressHash) => {
            const payload = {
              profileHash: profile.hash,
              reelHash: reel.hash,
              jobHash: job.hash,
              addressHash,
            };
            console.log(payload);
            return applicationService.create(payload);
          })
        );

        const apps: Application[] = [];

        for (const appRes of applicationsRes) {
          if (appRes.status === 'rejected') {
            toast.error(
              appRes.reason?.message ?? 'Application submition failed.'
            );
          } else {
            apps.push(appRes.value);
          }
        }

        if (onApplySuccess) {
          await onApplySuccess(apps);
        }

        router.replace(successUrl);
      } catch (err: any) {
        toast.error(
          isAxiosError(err) ?
            err.response?.data.message
          : 'Failed to submit application'
        );
      } finally {
        setIsApplying(false);
      }
    }, [
      onApplySuccess,
      reel,
      profile,
      job,
      isApplying,
      isReelReady,
      isPreparingReel,
      onSuccessUrl,
    ]);

    useEffect(() => {
      if (isReelReady && isStillLoading) {
        setIsStillLoading(false);
        onApply();
      }
    }, [isReelReady, isStillLoading, profile?.isPublic, onApply]);

    return (
      <>
        <button
          ref={ref}
          disabled={isDisabled}
          onClick={onApply}
          className={cn(
            'inline-flex items-center justify-center w-full h-10 sm:h-12 px-3 sm:px-4 rounded-full text-lg font-medium bg-ruby text-white transition-colors hover:bg-ruby/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-black',
            isDisabled &&
              'bg-zinc-100 text-zinc-500 hover:bg-zinc-100/90 cursor-progress',
            className
          )}
          {...props}
        >
          {isLoading && isLoadingChidlren && !isApplying && (
            <>{isLoadingChidlren}</>
          )}

          {isPreparingReel && isStillLoading && !isApplying && (
            <>
              Preparing Your Application
              <Loader className='w-5 h-5 ml-2 text-inherit' />
            </>
          )}

          {isApplying && (
            <>
              Submitting your application...{' '}
              <Loader className='w-5 h-5 ml-2 text-inherit' />
            </>
          )}

          {!isStillLoading && !isReelErrored && !isLoading && !isApplying && (
            <>{children}</>
          )}
        </button>

        {showAddresses && (
          <AddressOverlayV2
            open={showAddresses}
            setOpen={setShowAddresses}
            // @ts-ignore
            addresses={job?.addresses ?? []}
            onSubmit={async (addresses) => {
              jobAddresses(addresses);
              setShowAddresses(false);
            }}
          />
        )}
      </>
    );
  }
);
