import { JobStatus } from '@/modules/job/job.model';
import {
  Job_FindBySlug,
  Job_Get,
  Job_GetLink,
} from '@/chore/services/Job.service';
import { jobService } from '@/chore/services/v2/Jobs.service';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

export function useJob(jobHash: string, addressHash?: string) {
  const query = useQuery({
    queryKey: [`jobs-${jobHash}`, addressHash],
    queryFn: async () => Job_Get(jobHash),
    refetchInterval: 5 * 60 * 1000,
    enabled: !!jobHash.length,
  });
  return query;
}

export function useListJobPerAddress(params: {
  businessHash: string;
  addressHash?: string;
  skip?: number;
  limit?: number;
  filterStatus?: JobStatus;
}) {
  const query = useInfiniteQuery({
    queryKey: [
      `job-per-address`,
      params.businessHash,
      params.addressHash,
      params.filterStatus,
    ],
    queryFn: async ({ pageParam }) => {
      return await jobService.listJobPerAddress({
        skip: (params.skip ?? 10) * pageParam,
        ...params,
      });
    },
    enabled: !!params.businessHash.length,
    refetchInterval: 1 * 60 * 1000,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage.hasMore) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
  });
  return query;
}

export function useJobBySlug(businessSlug: string, jobSlug: string) {
  const query = useQuery({
    queryKey: [`job-${jobSlug}-business-${businessSlug}`],
    queryFn: async () => await Job_FindBySlug(businessSlug, jobSlug),
    refetchInterval: 30 * 60 * 1000,
    enabled: !!businessSlug.length && !!jobSlug.length,
  });
  return query;
}

export function useJobLink(jobHash: string) {
  const query = useQuery({
    queryKey: [`job-${jobHash}-link`],
    queryFn: async () => await Job_GetLink(jobHash),
    enabled: !!jobHash,
  });
  return query;
}
