export enum Colors {
  Ruby = '#FD2C54',
  RoyalBlue = '#030337',
  Aqua = '#21C9CC',
  Black = '#000',
}

export type TailwindColor =
  | 'slate'
  | 'gray'
  | 'zinc'
  | 'neutral'
  | 'stone'
  | 'red'
  | 'orange'
  | 'amber'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'emerald'
  | 'teal'
  | 'cyan'
  | 'sky'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'purple'
  | 'fuchsia'
  | 'pink'
  | 'rose'
  | 'black'
  | 'white';

export type TailwindColorType = 'border' | 'text' | 'ring' | 'bg';
export type TailwindColorOpacity =
  | 0
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50
  | 55
  | 60
  | 65
  | 70
  | 80
  | 85
  | 90
  | 95
  | 100
  | 'default';

export type TailwindColorModifier = string;
export const twColor = (
  type: TailwindColorType,
  color: TailwindColor,
  opacity?: TailwindColorOpacity
): TailwindColorModifier => {
  let _color = [type, color].join('-');
  if (opacity) _color = _color + '/' + opacity;
  return _color;
};
